import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import Taglist from "../components/taglist"
import "./blog.scss"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              date
              tags
            }
            excerpt
            html
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const { edges } = data.allMarkdownRemark

  const posts = edges.map(edge => {
    const { frontmatter, fields } = edge.node

    return (
      <li key={frontmatter.title} className="post">
        <Link to={`/${fields.slug}`}>
          <div>
            <h4 className="title">{frontmatter.title}</h4>
            <p className="date">{frontmatter.date}</p>
          </div>
          <Taglist tags={frontmatter.tags} />
        </Link>
        {/* <p>{excerpt}</p> */}
        {/* <Link to={`/${fields.slug}`}>See more...</Link> */}
      </li>
    )
  })

  const padding = { paddingLeft: "1rem" }
  const description =
    "I write about web development and the javascript ecosystem."

  return (
    <div className="blog-page">
      <SEO pathname="/blog" title="Blog" lang="en" description={description} />

      <Layout>
        <h1 style={padding}>Welcome to my blog.</h1>
        <p style={padding}>{description}</p>
        <br />
        <ul>{posts}</ul>
        <p style={padding}>
          Go back <Link to="/">home</Link>
        </p>
      </Layout>
    </div>
  )
}

export default BlogPage
